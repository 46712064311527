@use "./variable" as *;
@use "./mixin" as *;
@use "./keyframes";

/* ==========================================================
 html5 elements
========================================================== */
article, aside, figure, figcaption, footer, header, nav, section, main {
  display: block;
}

/* ==========================================================
 reseting
========================================================== */
body, div, pre, p, blockquote, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, form, fieldset, th, td, button, figure, figcaption {
  margin: 0;
  padding: 0;
}

input, textarea,
select {
  margin: 0;
  font-size: 16px;
}


button {
  padding: 0;
  border: none;
  overflow: visible;
  background: none;
  vertical-align: top;
  font: inherit;
  color: inherit;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  transition: 0.3s ease 0s;
  @include media(tab-min) {
    img {
      transition: 0.3s ease 0s;
    }
    &[href]:hover {
      opacity: 0.7;
      img {
        opacity: 0.7;
      }
    }
  }
}

label {
  cursor: pointer;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 100%;
}

fieldset, img {
  border: 0;
}

img {
  height: auto;
  vertical-align: top;
}

address, caption, code, em, th {
  font-style: normal;
  font-weight: normal;
}

ol, ul {
  list-style: none;
}

caption, th {
  text-align: left;
}

h1, h2, h3, h4, h5, h6, strong, em {
  font-size: 100%;
  font-weight: inherit;
}
h1, h2, h3, h4, h5, h6 {
  line-height: 1.5;
}

a, input { /* outline: none; -webkit-tap-highlight-color:rgba(0,0,0,0);*/}

// 電話番号
// [href^="tel"] {
//   @include media(tab) {
//     pointer-events: none;
//     cursor: auto;
//   }
// }

sup, sub {
  font-size: 63%;
}

sup {
  font-size: 75.5%;
  vertical-align: top;
}

sub {
  font-size: 75.5%;
  vertical-align: bottom;
}

hr {
  border: none;
  border-bottom: solid 1px $c_border;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  // -webkit-tap-highlight-color:rgba(0,0,0,0);
}

html {
  height: 100%;
  font-size: 10px;
}

body {
  min-height: 100%;
  background: $c_base;
  font-size: $f_size_pc;
  font-family: $f_base_all;
  line-height: 1.75;
  color: $c_text;
  -webkit-text-size-adjust: 100%;
  overflow-x: hidden;
  position: relative;
  &:lang(en),
  &:lang(zh-cn),
  &:lang(zh-tw),
  &:lang(zh-hk) {
    font-family: $f_base_all;
  }
  @include media(sp) {
    font-size: $f_size_sp;
  }

}

a {
  color: inherit;
  text-decoration: none;
  transition: 0.3s ease 0s;
  &[href^="tel"] {
    color: inherit;
  }
  @include media(tab-min) {
    img {
      transition: 0.3s ease 0s;
    }
    &[href]:hover {
      opacity: 0.7;
      img {
        opacity: 0.7;
      }
    }
  }
}

img {
  max-width: 100%;
  vertical-align: top;
}

i {
  font-style: normal;
}

.only-pc {
  @include media(sp) {
    display: none !important;
  }
}
.only-sp {
  @include media(tab-min) {
    display: none !important;
  }
}
.only-sp-land {
  display: none !important;
  @include media(360px) {
    display: block !important;
  }
}
.only-ss {
  @include media(ss-min) {
    display: none !important;
  }
}

.is-required {
  &::after {
    content: "*";
    margin: 0 0.5em;
    color: $c_error;
  }
}
.cf {
  @include cf;
}

input {
  &[type="text"],
  &[type="password"],
  &[type="email"],
  &[type="tel"],
  &[type="search"],
  &[type="date"] {
    height: 32px;
    padding: 0 10px;
    border: none;
    border-radius: 4px;
    box-shadow: none;
    -webkit-appearance: none;
    &[size="60"] {
      width: 100%;
    }
    &::placeholder {
      color: $c_text_gray;
    }
  }
}
textarea {
  width: 100%;
  min-height: 200px;
  padding: 7px 10px;
  border: none;
  border-radius: 4px;
  box-shadow: none;
  -webkit-appearance: none;
}